import './scss/main.scss';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import DrawSVGPlugin from 'gsap/DrawSVGPlugin';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(DrawSVGPlugin);

gsap.from('.logo', { y: -100, duration: 0.75, ease: 'bounce(4)' });

gsap.from('section#intro .stroke path', {
  scrollTrigger: {
    trigger: 'section#intro',
    start: 'top 50%',
    end: 'bottom top',
    toggleActions: 'restart none none none',
    onLeaveBack: (self) => self.disable(),
  },
  drawSVG: '0%',
  duration: 3,
  ease: 'power3',
});

const keyVisualTriggers = ['section#hi .colored-tile', 'section#potential .colored-tile', 'section#launch .colored-tile'];

keyVisualTriggers.forEach((trigger) => {
  gsap.fromTo(
    `${trigger} g`,
    {
      scale: 0,
      opacity: 0,
    },
    {
      scrollTrigger: {
        trigger: trigger,
        id: trigger,
        start: 'top 70%',
        toggleActions: 'restart none none none',
        onLeaveBack: (self) => self.disable(),
      },
      duration: 0.7,
      stagger: 0.075,
      ease: 'back',
      scale: 1,
      opacity: 1,
    }
  );
});

const strokeTriggers = ['section#hi .stroke path', 'section#potential .stroke path', 'section#launch .stroke path', 'section#team .stroke path'];

strokeTriggers.forEach((trigger) => {
  gsap.from(trigger, {
    scrollTrigger: {
      trigger: trigger,
      id: trigger,
      scrub: 1,
      start:
        'top 80%' /* default is top bottom, 1: scroll trigger, 2: scroll element (e.g. path and window), px and percentage vals are always relative to the TOP! */,
      end: 'bottom 200px' /* default bottom top, so when bottom of trigger hits top of window */,
      toggleActions: 'restart complete reverse complete',
      /* elements goes in forwards, elements goes out forwards, elements goes in backwards, element goes out backwards */
      /* keywords: play pause resume reverse restart reset complete none */
    },
    drawSVG: '0%',
    duration: 2,
    ease: 'power',
  });
});

const proxy = { skew: 0, y: 0 };
const skewSetter = gsap.quickSetter('.team-member-visual .team-member-avatar-wrapper', 'skewY', 'deg');
const clamp = gsap.utils.clamp(-30, 30);

ScrollTrigger.create({
  onUpdate: (self) => {
    const skew = clamp(self.getVelocity() / -300);
    const y = clamp(self.getVelocity() / -100);
    if (Math.abs(skew) > Math.abs(proxy.skew)) {
      proxy.skew = skew;
      proxy.y = y;
      gsap.to(proxy, {
        skew: 0,
        y: 0,
        duration: 2,
        ease: 'back',
        overwrite: true,
        onUpdate: () => {
          skewSetter(proxy.skew);
        },
      });
    }
  },
});

gsap.set('.team-member-visual svg', { transformOrigin: 'right center', force3D: true });

const sleepingTurkey = gsap.timeline({ repeat: -1, repeatDelay: 1 });

sleepingTurkey.set('.mouth-open', { display: 'none' });
sleepingTurkey.set('.mouth-closed', { display: 'block' });

sleepingTurkey.fromTo(
  '.sleeping-z',
  {
    scale: 0,
  },
  {
    scale: 1,
    y: -20,
    stagger: 0.2,
    ease: 'back',
  }
);

sleepingTurkey.to('.sleeping-z', {
  scale: 0,
  stagger: 0.1,
  ease: 'back',
});

sleepingTurkey.set('.mouth-open', { display: 'block' });
sleepingTurkey.set('.mouth-closed', { display: 'none' });
